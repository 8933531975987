import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutUs from './views/AboutUs';
import Contact from './views/Contact';
import FormSubmission from './views/Form';
import Home from './views/Home';
import PrivacyPolicy from './views/PrivacyPolicy';
import Questions from './views/Questions';
import Verify from './views/Verify';
import VerifyPCR from './views/VerifyPCR';
import TestingLocations from './views/TestingLocations';
import ComingSoon from './views/ComingSoon';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/about-us' element={<AboutUs />}></Route>
          <Route exact path='/coming-soon' element={<ComingSoon />}></Route>
          <Route exact path='/testing-locations' element={<TestingLocations />}></Route>
          <Route exact path='/faq' element={<Questions />}></Route>
          <Route exact path='/contact' element={<Contact />}></Route>
          <Route
            exact
            path='/privacy-policy'
            element={<PrivacyPolicy />}
          ></Route>
          <Route path='/register' element={<FormSubmission />}></Route>
          <Route path='/verify' element={<Verify />}></Route>
          <Route path='/verify-pcr' element={<VerifyPCR />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
