import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT,
  LOGOUT_REQUEST,
  LOGOUT_FAIL,
} from '../../constants/auth/auth';

import { CLEAR_MESSAGE, CLEAR_ALERT } from '../../constants/error/error';

// **  Initial State
const initialState = {
  userData: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };

    case LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        authError: action.payload,
      };

    case LOGOUT_REQUEST:
      return {
        loading: true,
        userData: null,
      };

    case LOGOUT:
      return {
        loading: false,
        userData: null,
      };

    case LOGOUT_FAIL:
      return {
        loading: false,
      };

    case CLEAR_ALERT:
      return {
        ...state,
        authError: '',
      };

    default:
      return state;
  }
};

export default authReducer;
