import React, { useEffect } from 'react';
import icon from '../assets/help-circle.svg';
import { Layout } from './Home';
import ReactGA from 'react-ga';

const Questions = () => {
  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {/* {locationsLoad && <Spinner />} */}
      <Layout>
        <div className='hero faqAbout'>
          <div style={{ margin: '0 20px' }}>
            <h1>Frequently Asked Questions</h1>
            <p>Get Informed</p>
          </div>
        </div>
        <div className='main mainAbout'>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            Do I have to schedule an appointment?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            We do not schedule appointments. We are a walk-in only clinic.
          </p>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            When will I receive my results?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Our rapid antigen test results are available within an hour. PCR testing results can be expected within 24-48
            hours.
            <br />
            <br />
            **Surges or outbreaks of the Covid-19 virus are expected during
            these uncertain times. This may cause prolonged turn around times.
          </p>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            What tests do you offer?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            We offer rapid antigen test to detect specific proteins on the
            surface of the coronavirus. We also offer PCR test that can detect
            small amounts of the coronavirus' genetic material in a specimen
            collected from a human.
          </p>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            What do I need to bring with me?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            In order for your testing process to go as smooth as possible you
            will need to bring the following:
            <ul>
              <li>A mask</li>
              <li>
                Drivers license, state ID, US/foreign passport OR any other
                government issued ID.
              </li>
              <li>
                Insurance card (insurance is required).
              </li>
            </ul>
          </p>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            Why do I need my insurance card if it is free?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Insurance companies pay for the tests 100%. There are thousands of
            people being tested everyday and the insurance companies are paying
            to help us stay on top of this health care crisis.
          </p>

          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            Is this lab CLIA Certified?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Yes, we are a CLIA Certified lab.
            <br />
            <br />
            **The objective of the CLIA program is to ensure quality laboratory
            testing.
          </p>

          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            How is your test preformed?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Our rapid antigen is a nasal swab. Our PCR test detects the COVID-19
            virus through saliva sample.
          </p>
          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            Can Children get tested?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Yes, over the age of two.
          </p>

          <h1 style={{ textAlign: 'left' }}>
            <img src={icon} alt='question' style={{ marginRight: 20 }} />
            Cash Price?
          </h1>
          <p style={{ textAlign: 'left', marginBottom: 50 }}>
            Rapid Antigen is $100
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Questions;
