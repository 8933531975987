import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCallback, useEffect, useRef, useState, useScript } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { useLocation, useNavigate } from 'react-router-dom';

import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import '../App.css';
import logo from '../assets/logoCst.svg';
import refresh from '../assets/refresh.svg';
import PhoneInput from 'react-phone-number-input/input';
import { formatPhoneNumber } from '../helpers';
import DatePicker from 'react-date-picker';
//import DatePicker from "react-date-picker/dist/entry.nostyle";
import 'react-calendar/dist/Calendar.css';
//import 'react-datepicker/src/stylesheets/datepicker.scss';
import ReactGA from 'react-ga';

import moment from 'moment';

export const Spinner = () => {
  return (
    <div className='spinnerFullScreen'>
      <div className='spinner'>
        <div className='loadingio-spinner-eclipse-934ubq7deah'>
          <div className='ldio-mluog6ocoxi'>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Required = () => {
  return <span className='required'>*</span>;
};

const customStylesInvalid = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: ' 1px solid #dc3545',
  }),
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: ' 1px solid #ced4da',
  }),
};

const ThankYou = ({ cstId, setCstId }) => {
  return (
    <div className='App'>
      <Container style={{ maxWidth: 800, textAlign: 'center' }}>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <p>Your registration has been submitted.</p>
        <p>
          Confirmation ID: <strong>{cstId}</strong>
        </p>
        <Button className='mb-4' onClick={() => window.location.reload(false)}>
          Done
        </Button>
      </Container>
    </div>
  );
};

const RedirectingSplash = ({ cstId, setCstId }) => {
  useEffect(() => {
    window.location.replace(
      'https://www.convergepay.com/hosted-payments?ssl_txn_auth_token=0xMzMDUNSHWWzhJhxnaBDwAAAYNbm%2FN1'
    );
  }, []);

  return (
    <div className='App'>
      <Container style={{ maxWidth: 800, textAlign: 'center' }}>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <div>
          <p>
            <b>DO NOT CLOSE THIS PAGE</b>
          </p>
          <p>Redirecting to payment portal... </p>
        </div>
      </Container>
    </div>
  );
};

const API_URL = process.env.REACT_APP_API_URL;

const FormSubmission = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [message, setMessage] = useState('');

  const [cstId, setCstId] = useState(null);
  const [redirectingToPaymentPortal, setRedirectingToPaymentPortal] =
    useState(false);

  const [gender, setGender] = useState(null);
  const [hasPrivateInsurace, setHasPrivateInsurace] = useState(null);
  const [wantsToReceiveAtHomeCovidTest, setWantsToReceiveAtHomeCovidTest] =
    useState(false);
  const [usaStates, setUsaStates] = useState({});
  const [testingLocations, setTestingLocations] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [race, setRace] = useState({});
  const [ethnicity, setEthnicity] = useState({});
  const [relationToPolicyHolder, setRelationToPolicyHolder] = useState({});
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [streetAddress, setStreetAddress] = useState(null);
  const [streetNumber, setStreetNumber] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState({});
  const [id, setId] = useState('');
  const [identification, setIdentification] = useState('');
  const [insurance, setInsurance] = useState({});
  const [insuranceCardFront, setInsuranceCardFront] = useState('');
  const [insuranceCardBack, setInsuranceCardBack] = useState('');
  const [groupNumber, setGroupNumber] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [attestationForm, setAttestationForm] = useState('');

  //symptoms
  const [cough, setCough] = useState(false);
  const [fever, setFever] = useState(false);
  const [breath, setBreath] = useState(false);
  const [fatigue, setFatigue] = useState(false);
  const [smell, setSmell] = useState(false);
  const [taste, setTaste] = useState(false);
  const [muscle, setMuscle] = useState(false);
  const [throat, setThroat] = useState(false);
  const [headache, setHeadache] = useState(false);
  const [nose, setNose] = useState(false);
  const [vomiting, setVomiting] = useState(false);
  const [exposure, setExposure] = useState(false);

  // purpose
  const [PurposeExposure, setPurposeExposure] = useState(false);
  const [PurposeSymptoms, setPurposeSymptoms] = useState(false);
  const [PurposeInfectionResolved, setPurposeInfectionResolved] =
    useState(false);
  const [PurposeOther, setPurposeOther] = useState(false);

  const [consent, setConsent] = useState(false);
  const [todaysDate, setTodaysDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  //signature
  const [imageURL, setImageURL] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [locations, setLocations] = useState([]);

  const [countriesLoad, setCountriesLoad] = useState(false);
  const [statesLoad, setStatesLoad] = useState(false);
  const [insuranceProvidersLoad, setInsuranceProvidersLoad] = useState(false);
  const [locationsLoad, setLocationsLoad] = useState(false);

  const [validated, setValidated] = useState(false);

  const [dobValid, setDobValid] = useState('');

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const RegistrationAnalytics = (id) => {
    if (id) {
      ReactGA.event({
        category: 'PatientRegistration',
        action: 'Patient Registered',
      });
    }
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const configPost = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const fetchCountries = useCallback(async () => {
    setCountriesLoad(true);
    const data = await axios.get(`${API_URL}/Countries`, config);
    //console.log(data.data);
    setCountries(data.data);
    setCountriesLoad(false);
  }, []);

  const fetchStates = useCallback(async () => {
    setStatesLoad(true);
    const data = await axios.get(`${API_URL}/States`, config);
    setStates(data.data);
    setStatesLoad(false);
  }, []);

  const fetchInsuranceProviders = useCallback(async () => {
    setInsuranceProvidersLoad(true);
    const data = await axios.get(`${API_URL}/InsuranceProviders`, config);
    setInsuranceProviders(data.data);
    setInsuranceProvidersLoad(false);
  }, []);

  const fetchLocations = useCallback(async () => {
    setLocationsLoad(true);
    const data = await axios.get(
      `${API_URL}/Locations?visibleOnly=true`,
      config
    );
    setLocations(data.data);
    setLocationsLoad(false);
  }, []);

  useEffect(() => {
    fetchCountries().catch(console.error);
    fetchStates().catch(console.error);
    fetchInsuranceProviders().catch(console.error);
    fetchLocations().catch(console.error);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAlf8rzc8Q9lJQhELmu_VAh_nLG7ISkS58&libraries=places&callback=Function.prototype';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function formatDate(date) {
    var d = `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
    return d;
  }

  const GenderFormat = () => {
    if (gender === 'male') {
      return 1;
    } else if (gender === 'female') {
      return 2;
    } else if (gender === 'other') {
      return 3;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      console.log('validation failed');
    }

    setValidated(true);
    setLoading(true);

    let formData = new FormData();

    formData.append('Sex', GenderFormat());
    formData.append('StateId', usaStates.value);
    formData.append('TestingLocationSlug', testingLocations.value);
    formData.append('FirstName', firstName);
    formData.append('LastName', lastName);
    formData.append('Phone', phone);
    formData.append('Dob', formatDate(new Date(dateOfBirth)));
    formData.append('Race', race.value);
    formData.append('Ethnicity', ethnicity.value);
    formData.append('Email', email);
    formData.append('AddressLine1', streetAddress?.label);
    formData.append('AddressLine2', addressLine2);
    formData.append('StreetNumber', streetNumber);
    formData.append('City', city);
    formData.append('Province', region);
    formData.append('County', county);
    formData.append('Zip', zip);
    formData.append('CountryId', country.value);
    formData.append('LegalDocumentNumber', id);
    formData.append('PhotoIdentificationDocument', identification);

    if (hasPrivateInsurace) {
      formData.append('HasPrivateInsurance', true);
      formData.append('HealthInsuranceProviderId', insurance.value);
      formData.append('HealthInsuranceCardDocument', insuranceCardFront);
      formData.append('HealthInsuranceCardDocumentBackside', insuranceCardBack);
      formData.append('HealthInsuranceGroupNumber', groupNumber);
      formData.append('HealthInsuranceIdNumber', identificationNumber);
      formData.append('RelationToPolicyHolder', relationToPolicyHolder.value);
      formData.append(
        'WantsToReceiveAtHomeCovidTest',
        wantsToReceiveAtHomeCovidTest
      );
    } else {
      formData.append('HasPrivateInsurance', false);
      //formData.append('HealthInsuranceProviderId', null);
      if (socialSecurityNumber) {
        formData.append('Ssn', socialSecurityNumber);
      }
      if (attestationForm) {
        formData.append('AttestationFormDocument', attestationForm);
      }
    }

    formData.append('CovidSymptoms.Cough', cough);
    formData.append('CovidSymptoms.Fever', fever);
    formData.append('CovidSymptoms.ShortnessOfBreath', breath);
    formData.append('CovidSymptoms.Fatigue', fatigue);
    formData.append('CovidSymptoms.LossOfSmell', smell);
    formData.append('CovidSymptoms.LossOfTaste', taste);
    formData.append('CovidSymptoms.MuscleAches', muscle);
    formData.append('CovidSymptoms.SoreThroat', throat);
    formData.append('CovidSymptoms.Headache', headache);
    formData.append('CovidSymptoms.CongestionOrRunnyNose', nose);
    formData.append('CovidSymptoms.NauseaOrVomiting', vomiting);
    formData.append('CovidSymptoms.PotentialExposureToCovid19', exposure);

    formData.append('PurposeOfTesting.Exposure', PurposeExposure);
    formData.append('PurposeOfTesting.Symptoms', PurposeSymptoms);
    formData.append(
      'PurposeOfTesting.InfectionResolvedCheck',
      PurposeInfectionResolved
    );
    formData.append('PurposeOfTesting.Other', PurposeOther);

    formData.append('TodaysDate', todaysDate);
    formData.append('SignatureDocumentBase64', imageURL);

    try {
      const PleaseProvideAllRequiredFields = () => {
        setError('Please provide all required fields');
        setTimeout(() => {
          setError('');
        }, 5000);
      };

      if (!ValidateDOB()) {
        console.log('date not ok');
      } else if (email !== confirmEmail) {
        setError('Email addresses don’t match');
        setTimeout(() => {
          setError('');
        }, 5000);
      } else if (!emailValidation()) {
        setError('Email is not valid');
        setTimeout(() => {
          setError('');
        }, 5000);
      } else if (phone?.[1] == 0 || phone?.[1] == 1) {
        setError('Phone number is not valid');
        setTimeout(() => {
          setError('');
        }, 5000);
      } else if (!consent) {
        PleaseProvideAllRequiredFields();
      } else if (!symptomsInvalid) {
        PleaseProvideAllRequiredFields();
      } else if (!purposeInvalid) {
        PleaseProvideAllRequiredFields();
      } else if (areInputsValid) {
        console.log(formData);

        const data = await axios.post(
          `${API_URL}/PatientRegistrations`,
          formData,
          configPost
        );
        setMessage(data.data.message);
        setTimeout(() => {
          setMessage('');
        }, 5000);

        if (hasPrivateInsurace) {
          setCstId(data.data.cstId);
        } else {
          setRedirectingToPaymentPortal(true);
        }
      } else {
        PleaseProvideAllRequiredFields();
      }
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

  const HandleChange = (e, setState) => {
    setState(e.target.value);
  };

  const search = useLocation().search;
  const name = new URLSearchParams(search).get('location');
  const state = new URLSearchParams(search).get('state');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  useEffect(() => {
    if (name && locations.length > 0) {
      const lcc = locations
        .map((item) => ({
          value: item.slug,
          label: item.address,
        }))
        .filter((loc) => loc.value === name);
      setTestingLocations(lcc[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (state && states.length > 0) {
      const stts = states
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
        .filter((loc) => loc.value == state);
      setUsaStates(stts[0]);
    }
  }, [states]);

  const insuranceArray = () => {
    const arr = insuranceProviders.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    //arr.shift(); --TODO: This was here to remove the first item HRSA NO Insurace. Remove.
    return arr;
  };

  const uploadImageIdentification = (e) => {
    setIdentification(e.target.files[0]);
  };

  const uploadImageInsuranceFront = (e) => {
    setInsuranceCardFront(e.target.files[0]);
  };

  const uploadImageInsuranceBack = (e) => {
    setInsuranceCardBack(e.target.files[0]);
  };

  const uploadAttestationForm = (e) => {
    setAttestationForm(e.target.files[0]);
  };

  const canvasValidation = () => {
    if (validated && !imageURL) {
      return 'sigCanvasInvalid';
    } else {
      return 'sigCanvas';
    }
  };

  const tryGetPlaceComponent = (place, name) => {
    try {
      var c = place.address_components.find((x) => x.types.includes(name));
      var val = c !== null ? c.long_name : null;
      return val;
    } catch (error) {
      return null;
    }
  };

  const trySetCountry = (name) => {
    setCountry({ value: 231, label: 'United States' });
    // try {
    //   var listCountry = countries.find((x) => x.name == name);
    //   if (listCountry !== null) {
    //     setCountry({ value: listCountry.id, label: listCountry.name });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  if (streetAddress !== null) {
    var helper = document.getElementById('service-helper');

    if (helper !== null) {
      var service = new window.google.maps.places.PlacesService(
        document.getElementById('service-helper')
      );

      service.getDetails(
        {
          placeId: streetAddress.value.place_id,
        },
        function (place, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            try {
              //console.log(place.address_components);

              var streetNumber = tryGetPlaceComponent(place, 'street_number');
              var route = tryGetPlaceComponent(place, 'route');
              var state = tryGetPlaceComponent(
                place,
                'administrative_area_level_1'
              );
              var zip = tryGetPlaceComponent(place, 'postal_code');
              var city = tryGetPlaceComponent(place, 'locality');
              if (city === null)
                city = tryGetPlaceComponent(place, 'sublocality');
              var country = tryGetPlaceComponent(place, 'country');
              var county = tryGetPlaceComponent(
                place,
                'administrative_area_level_2'
              );

              if (route !== null) {
                if (streetNumber !== null) {
                  setStreetNumber(streetNumber);
                }
                setRegion(state);

                if (zip !== null) {
                  setZip(zip);
                }

                setCity(city);
                trySetCountry(country);
                setCounty(county);
                setStreetAddress({ label: route, value: streetAddress.value });
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      );
    }
  }

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      setError('Email is not valid');
      setTimeout(() => {
        setError('');
      }, 5000);
      return false;
    }
    return true;
  };

  const races = [
    { label: 'Caucasian', value: 1 },
    { label: 'African American', value: 2 },
    { label: 'American Indian or Alaska Native', value: 3 },
    { label: 'Latino or Hispanic', value: 4 },
    { label: 'Asian', value: 5 },
    { label: 'Pacific Islander or Hawaiian', value: 6 },
  ];

  const ethnicities = [
    { label: 'Hispanic', value: 1 },
    { label: 'Non-Hispanic', value: 2 },
  ];

  const relations = [
    { label: 'Self', value: 1 },
    { label: 'Spouse', value: 2 },
    { label: 'Dependent', value: 3 },
  ];

  const symptomsInvalid =
    exposure ||
    vomiting ||
    nose ||
    headache ||
    throat ||
    muscle ||
    taste ||
    smell ||
    fatigue ||
    breath ||
    fever ||
    cough;

  const purposeInvalid =
    PurposeExposure ||
    PurposeSymptoms ||
    PurposeInfectionResolved ||
    PurposeOther;

  const SsnValidator = socialSecurityNumber || attestationForm;

  const areInputsValid =
    gender &&
    usaStates.value &&
    testingLocations.value &&
    firstName &&
    lastName &&
    phone &&
    dateOfBirth &&
    race.value &&
    ethnicity.value &&
    streetAddress?.label &&
    city &&
    region &&
    zip &&
    country.value &&
    id &&
    identification &&
    (hasPrivateInsurace === false ||
      (insurance.value &&
        insuranceCardFront &&
        insuranceCardBack &&
        relationToPolicyHolder.value &&
        identificationNumber &&
        groupNumber)) &&
    todaysDate &&
    imageURL &&
    streetNumber;

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhone(formattedPhoneNumber);
    // console.log(formattedPhoneNumber);
  };
  // console.log(phone.length === 14);

  // useEffect(() => {
  //   console.log(emailValidation());
  // }, [email]);

  function ValidateDOB() {
    var lblError = document.getElementById('lblError');

    //Get the date from the TextBox.
    var dateString = moment(dateOfBirth).format('DD/MM/yyyy');
    //console.log(dateString);
    var regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;

    //Check whether valid dd/MM/yyyy Date Format.
    if (regex.test(dateString)) {
      var parts = dateString.split('/');
      var dtDOB = new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
      var dtCurrent = new Date();
      // lblError.innerHTML = 'Eligibility 18 years ONLY.';

      setError('Must be at least 2 years old');
      setTimeout(() => {
        setError('');
      }, 5000);
      setDobValid(true);

      if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 2) {
        return false;
      }

      if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 2) {
        //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
        if (dtCurrent.getMonth() < dtDOB.getMonth()) {
          return false;
        }
        if (dtCurrent.getMonth() == dtDOB.getMonth()) {
          //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
          if (dtCurrent.getDate() < dtDOB.getDate()) {
            return false;
          }
        }
      }
      // lblError.innerHTML = '';

      setError('');
      setDobValid(false);
      return true;
    } else {
      // lblError.innerHTML = 'Enter date in dd/MM/yyyy format ONLY.';
      setError('Enter date in dd/MM/yyyy format ONLY.');
      setTimeout(() => {
        setError('');
      }, 5000);
      setDobValid(true);
      return false;
    }
  }

  return (
    <>
      {loading ||
      locationsLoad ||
      insuranceProvidersLoad ||
      countriesLoad ||
      statesLoad ? (
        <Spinner />
      ) : (
        <>
          {cstId ? (
            <ThankYou cstId={cstId} setCstId={setCstId} />
          ) : (
            <>
              {redirectingToPaymentPortal ? (
                <RedirectingSplash />
              ) : (
                <div className='App'>
                  <Container style={{ maxWidth: 805 }}>
                    <div className='logo'>
                      <img src={logo} alt='logo' />
                    </div>
                    <h2>COVID-19 Testing Registration Form</h2>
                    <p>
                      Please complete all the fields on this form. If you have
                      any questions please speak with your specimen collector
                    </p>
                    <div className='separator' />
                    <div id='service-helper' hidden={true}></div>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md>
                          <FormGroup controlId='usaStates' className='mb-4'>
                            <FormLabel className='emph'>
                              State
                              <Required />
                            </FormLabel>
                            <Select
                              options={states.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              styles={
                                validated && !usaStates.value
                                  ? customStylesInvalid
                                  : customStyles
                              }
                              onChange={(e) => setUsaStates(e)}
                              value={usaStates}
                              label='Select State'
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup
                            controlId='testingLocations'
                            className='mb-4'
                          >
                            <FormLabel className='emph'>
                              Testing Site Location
                              <Required />
                            </FormLabel>
                            <Select
                              options={locations.map((item) => ({
                                value: item.slug,
                                label: item.address,
                              }))}
                              styles={
                                validated && !testingLocations.value
                                  ? customStylesInvalid
                                  : customStyles
                              }
                              onChange={(e) => setTestingLocations(e)}
                              value={testingLocations}
                              label='Select State'
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='firstName' className='mb-4'>
                            <FormLabel className='emph'>
                              First Name
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) =>
                                setFirstName(
                                  e.target.value.replace(/[0-9]/g, '')
                                )
                              }
                              value={firstName}
                              isInvalid={validated && !firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='lastName' className='mb-4'>
                            <FormLabel className='emph'>
                              Last Name
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) =>
                                setLastName(
                                  e.target.value.replace(/[0-9]/g, '')
                                )
                              }
                              value={lastName}
                              isInvalid={validated && !lastName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='phone' className='mb-4'>
                            <FormLabel className='emph'>
                              Phone
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='tel'
                              onChange={(e) => handleInput(e)}
                              value={phone}
                              required
                              isInvalid={validated && phone.length != 14}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='dateOfBirth' className='mb-4'>
                            <FormLabel className='emph'>
                              Date of birth
                              <Required />
                            </FormLabel>
                            {/* <FormControl
                          type='date'
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          value={dateOfBirth}
                          required
                          isInvalid={validated && !dateOfBirth}
                        /> */}
                            <div>
                              <DatePicker
                                onChange={setDateOfBirth}
                                value={dateOfBirth}
                                openCalendarOnFocus={false}
                                format={' MM/dd/yyyy'}
                                yearPlaceholder='yyyy'
                                monthPlaceholder='mm'
                                dayPlaceholder='dd'
                                required
                                className={`form-control ${
                                  validated && dobValid && 'is-invalid'
                                }`}
                              />
                            </div>

                            <p className='mt-2'>
                              Testing is permitted to anyone ages 2 or older.
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='email' className='mb-4'>
                            <FormLabel className='emph'>
                              Email
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='email'
                              required
                              onChange={(e) => HandleChange(e, setEmail)}
                              value={email}
                              isInvalid={validated && !email}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='confirmEmail' className='mb-4'>
                            <FormLabel className='emph'>
                              Confirm Email
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='email'
                              required
                              onChange={(e) => HandleChange(e, setConfirmEmail)}
                              value={confirmEmail}
                              isInvalid={validated && !confirmEmail}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row md>
                        <Col>
                          <FormGroup controlId='streetAddress' className='mb-4'>
                            <FormLabel className='emph'>
                              Street Address
                              <Required />
                            </FormLabel>

                            <GooglePlacesAutocomplete
                              apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ['us'],
                                },
                              }}
                              selectProps={{
                                value: streetAddress,
                                onChange: setStreetAddress,
                                styles:
                                  validated && !streetAddress
                                    ? customStylesInvalid
                                    : customStyles,
                                placeholder: '',
                              }}
                              minLengthAutocomplete={5}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='StreetNumber' className='mb-4'>
                            <FormLabel className='emph'>
                              Street Number
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) => HandleChange(e, setStreetNumber)}
                              value={streetNumber}
                              isInvalid={validated && !streetNumber}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup controlId='addressLine2' className='mb-4'>
                            <FormLabel className='emph'>
                              Address Line 2
                            </FormLabel>

                            <FormControl
                              type='text'
                              onChange={(e) => HandleChange(e, setAddressLine2)}
                              value={addressLine2}
                              //isInvalid={validated && !streetNumber}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='city' className='mb-4'>
                            <FormLabel className='emph'>
                              City
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) => HandleChange(e, setCity)}
                              value={city}
                              isInvalid={validated && !city}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='Region' className='mb-4'>
                            <FormLabel className='emph'>
                              State / Province / Region
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) => HandleChange(e, setRegion)}
                              value={region}
                              isInvalid={validated && !region}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='zip' className='mb-4'>
                            <FormLabel className='emph'>
                              Zip / Postal
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) => HandleChange(e, setZip)}
                              value={zip}
                              isInvalid={validated && !zip}
                            />
                          </FormGroup>
                        </Col>
                        <Col md>
                          <FormGroup controlId='county' className='mb-4'>
                            <FormLabel className='emph'>County</FormLabel>
                            <FormControl
                              type='text'
                              onChange={(e) => HandleChange(e, setCounty)}
                              value={county}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='country' className='mb-4'>
                            <FormLabel className='emph'>
                              Country
                              <Required />
                            </FormLabel>
                            <Select
                              options={countries.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              styles={
                                validated && !country.value
                                  ? customStylesInvalid
                                  : customStyles
                              }
                              onChange={setCountry}
                              value={country}
                              label='Select State'
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup className='mb-4'>
                            <FormLabel className='emph'>
                              Sex
                              <Required />
                            </FormLabel>
                            <FormCheck
                              id='male'
                              value='male'
                              type='radio'
                              label='Male'
                              checked={gender === 'male'}
                              onChange={(e) => setGender(e.target.value)}
                              isInvalid={validated && !gender}
                            />
                            <FormCheck
                              id='female'
                              value='female'
                              type='radio'
                              label='Female'
                              checked={gender === 'female'}
                              onChange={(e) => setGender(e.target.value)}
                              isInvalid={validated && !gender}
                            />
                            <FormCheck
                              id='other'
                              value='other'
                              type='radio'
                              label='Other'
                              checked={gender === 'other'}
                              onChange={(e) => setGender(e.target.value)}
                              isInvalid={validated && !gender}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md>
                          <FormGroup controlId='race' className='mb-4'>
                            <FormLabel className='emph'>
                              Race
                              <Required />
                            </FormLabel>
                            <Select
                              options={races}
                              styles={
                                validated && !race.value
                                  ? customStylesInvalid
                                  : customStyles
                              }
                              onChange={setRace}
                              value={race}
                              label='Your race'
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col md>
                          <FormGroup controlId='ethnicity' className='mb-4'>
                            <FormLabel className='emph'>
                              Ethnicity
                              <Required />
                            </FormLabel>
                            <Select
                              options={ethnicities}
                              styles={
                                validated && !ethnicity.value
                                  ? customStylesInvalid
                                  : customStyles
                              }
                              onChange={setEthnicity}
                              value={ethnicity}
                              label='Your ethnicity'
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup controlId='id' className='mb-4'>
                            <FormLabel className='emph'>
                              Driver’s License Number, State ID Card Number or
                              Passport Number
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) => HandleChange(e, setId)}
                              value={id}
                              isInvalid={validated && !id}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup
                            controlId='identification'
                            className='mb-4'
                          >
                            <FormLabel className='emph'>
                              Driver’s License, State ID Card or Passport
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='file'
                              onChange={uploadImageIdentification}
                              required
                              isInvalid={validated && !identification}
                              name='identification'
                              placeholder='identification'
                            />
                            {identification &&
                              `Chosen file: ${identification?.name}`}
                          </FormGroup>
                          <p>
                            Please upload a photo of your Driver’s License,
                            State ID Card or Passport you used.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h2>Insurance Information</h2>
                          <p>
                            COVID-19 testing may be covered by private or public
                            health insurance and certain public health care
                            programs with no cost to the patient. Individuals
                            who present for COVID-19 testing with health
                            insurance will need to provide a copy of their
                            insurance card or insurance information at the time
                            of testing.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup controlId='insurance' className='mb-4'>
                            <FormLabel className='emph'>
                              Do you currently have health insurance through an
                              individual or employer-sponsored plan, a federal
                              or state health care program, the Federal
                              Employees Health Benefits Program, or from any
                              other source?
                              <Required />
                            </FormLabel>
                            <FormCheck
                              id='insurance-yes'
                              type='radio'
                              label='Yes'
                              checked={hasPrivateInsurace === true}
                              onChange={() => setHasPrivateInsurace(true)}
                              isInvalid={
                                validated && hasPrivateInsurace === null
                              }
                            />
                            <FormCheck
                              id='insurance-no'
                              type='radio'
                              label='No'
                              checked={hasPrivateInsurace === false}
                              onChange={() => setHasPrivateInsurace(false)}
                              isInvalid={
                                validated && hasPrivateInsurace === null
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {hasPrivateInsurace === true && (
                        <>
                          <Row>
                            <Col>
                              <FormGroup controlId='insurance' className='mb-4'>
                                <FormLabel className='emph'>
                                  Insurance
                                  <Required />
                                </FormLabel>
                                <Select
                                  options={insuranceArray()}
                                  styles={
                                    validated && !insurance.value
                                      ? customStylesInvalid
                                      : customStyles
                                  }
                                  onChange={(e) => setInsurance(e)}
                                  value={insurance}
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {insurance !== null &&
                            insurance.label === 'MEDICARE PART B' && (
                              <>
                                {/* bookmark */}

                                <Row>
                                  <Col>
                                    <FormGroup
                                      controlId='atHomeCovidTestQuestion'
                                      className='mb-4'
                                    >
                                      <FormLabel className='emph'>
                                        If eligible, would you be interested in
                                        receiving at home Covid tests through
                                        your insurance monthly for free when
                                        available? Only check yes if you are NOT
                                        receiving them as of now.
                                      </FormLabel>
                                      <FormCheck
                                        id='atHomeCovidTestQuestion-yes'
                                        type='radio'
                                        label='Yes'
                                        checked={
                                          wantsToReceiveAtHomeCovidTest === true
                                        }
                                        onChange={() =>
                                          setWantsToReceiveAtHomeCovidTest(true)
                                        }
                                        isInvalid={false}
                                      />
                                      <FormCheck
                                        id='atHomeCovidTestQuestion-no'
                                        type='radio'
                                        label='No'
                                        checked={
                                          wantsToReceiveAtHomeCovidTest ===
                                          false
                                        }
                                        onChange={() =>
                                          setWantsToReceiveAtHomeCovidTest(
                                            false
                                          )
                                        }
                                        isInvalid={false}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}

                          <Row>
                            <Col>
                              <FormGroup
                                controlId='insuranceCardFront'
                                className='mb-4'
                              >
                                <FormLabel className='emph'>
                                  Photocopy of your Insurance Card Frontside
                                  <Required />
                                </FormLabel>
                                <FormControl
                                  type='file'
                                  onChange={uploadImageInsuranceFront}
                                  required
                                  isInvalid={validated && !insuranceCardFront}
                                />
                                {insuranceCardFront &&
                                  `Chosen file: ${insuranceCardFront?.name}`}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroup
                                controlId='insuranceCardBack'
                                className='mb-4'
                              >
                                <FormLabel className='emph'>
                                  Photocopy of your Insurance Card Backside
                                  <Required />
                                </FormLabel>
                                <FormControl
                                  type='file'
                                  onChange={uploadImageInsuranceBack}
                                  required
                                  isInvalid={validated && !insuranceCardBack}
                                />
                                {insuranceCardBack &&
                                  `Chosen file: ${insuranceCardBack?.name}`}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md>
                              <FormGroup
                                controlId='groupNumber'
                                className='mb-4'
                              >
                                <FormLabel className='emph'>
                                  Group Number
                                  <Required />
                                </FormLabel>
                                <FormControl
                                  type='text'
                                  required
                                  onChange={(e) =>
                                    HandleChange(e, setGroupNumber)
                                  }
                                  value={groupNumber}
                                  isInvalid={validated && !groupNumber}
                                />
                                <p className='mt-2'>
                                  This information can be found on your
                                  insurance card
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md>
                              <FormGroup
                                controlId='identificationNumber'
                                className='mb-4'
                              >
                                <FormLabel className='emph'>
                                  Identification Number
                                  <Required />
                                </FormLabel>
                                <FormControl
                                  type='text'
                                  required
                                  onChange={(e) =>
                                    HandleChange(e, setIdentificationNumber)
                                  }
                                  value={identificationNumber}
                                  isInvalid={validated && !identificationNumber}
                                />
                                <p className='mt-2'>
                                  This information can be found on your
                                  insurance card
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md>
                              <FormGroup
                                controlId='relationWithPolicyholder'
                                className='mb-4'
                              >
                                <FormLabel className='emph'>
                                  Relation to Insurance Policyholder
                                  <Required />
                                </FormLabel>
                                <Select
                                  options={relations}
                                  styles={
                                    validated && !relationToPolicyHolder.value
                                      ? customStylesInvalid
                                      : customStyles
                                  }
                                  onChange={setRelationToPolicyHolder}
                                  value={relationToPolicyHolder}
                                  label='Your relation to policyholder'
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}

                      {hasPrivateInsurace === false && (
                        <>
                          <p style={{ color: '#1266F1' }}>
                            After submitting registration you will be redirected
                            for payment
                          </p>
                        </>
                      )}

                      {/* {hasPrivateInsurace === false && (
                    <>
                      <h3>SSN Information</h3>
                      <Row>
                        <Col>
                          <FormGroup
                            controlId='socialSecurityNumber'
                            className='mb-4'
                          >
                            <FormLabel className='emph'>
                              Social Security Number
                            </FormLabel>
                            <FormControl
                              type='text'
                              required
                              onChange={(e) =>
                                HandleChange(e, setSocialSecurityNumber)
                              }
                              value={socialSecurityNumber}
                              isInvalid={
                                validated &&
                                !socialSecurityNumber &&
                                !attestationForm
                              }
                            />
                          </FormGroup>
                          <p>
                            If you do not have a SSN, please ask the front desk
                            for an Attestation form, then upload a picture of
                            the signed Attestation form.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup
                            controlId='attestationForm'
                            className='mb-4'
                          >
                            <FormLabel className='emph'>
                              Signed Attestation form
                            </FormLabel>
                            <FormControl
                              type='file'
                              onChange={uploadAttestationForm}
                              required
                              isInvalid={
                                validated &&
                                !socialSecurityNumber &&
                                !attestationForm
                              }
                            />
                            {attestationForm &&
                              `Chosen file: ${attestationForm?.name}`}
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )} */}

                      <h2>Purpose of Testing</h2>
                      <Row>
                        <Col>
                          <FormGroup className='mb-4'>
                            <FormLabel className='emph'>
                              What is the purpose for which you are taking a
                              COVID-19 test at this time?
                              <Required />
                            </FormLabel>
                            <FormCheck
                              id={'PurposeExposure'}
                              type='checkbox'
                              label='Exposure or suspected exposure'
                              checked={PurposeExposure}
                              onChange={() =>
                                setPurposeExposure(!PurposeExposure)
                              }
                              isInvalid={validated && !purposeInvalid}
                            />
                            <FormCheck
                              id={'PurposeSymptoms'}
                              type='checkbox'
                              label='Symptoms related to COVID-19'
                              checked={PurposeSymptoms}
                              onChange={() =>
                                setPurposeSymptoms(!PurposeSymptoms)
                              }
                              isInvalid={validated && !purposeInvalid}
                            />
                            <FormCheck
                              id={'PurposeInfectionResolved'}
                              type='checkbox'
                              label='To determine whether a COVID-19 infection has resolved'
                              checked={PurposeInfectionResolved}
                              onChange={() =>
                                setPurposeInfectionResolved(
                                  !PurposeInfectionResolved
                                )
                              }
                              isInvalid={validated && !purposeInvalid}
                            />
                            <FormCheck
                              id={'PurposeOther'}
                              type='checkbox'
                              label='Travel, work, school, or other reason without potential exposure or symptoms (please note that your COVID-19 test may not be covered by insurance)'
                              checked={PurposeOther}
                              onChange={() => setPurposeOther(!PurposeOther)}
                              isInvalid={validated && !purposeInvalid}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <h2>Clinical Information</h2>
                      <Row>
                        <Col>
                          <FormGroup className='mb-4'>
                            <FormLabel className='emph'>
                              Do you have any of the following symptoms
                              <Required />
                            </FormLabel>
                            <FormCheck
                              id={'cough'}
                              type='checkbox'
                              label='Cough'
                              checked={cough}
                              onChange={() => setCough(!cough)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'fever'}
                              type='checkbox'
                              label='Fever'
                              checked={fever}
                              onChange={() => setFever(!fever)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'breath'}
                              type='checkbox'
                              label='Shortness of Breath'
                              checked={breath}
                              onChange={() => setBreath(!breath)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'fatigue'}
                              type='checkbox'
                              label='Fatigue'
                              checked={fatigue}
                              onChange={() => setFatigue(!fatigue)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'smell'}
                              type='checkbox'
                              label='Loss of Smell'
                              checked={smell}
                              onChange={() => setSmell(!smell)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'taste'}
                              type='checkbox'
                              label='Loss of Taste'
                              checked={taste}
                              onChange={() => setTaste(!taste)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'muscle'}
                              type='checkbox'
                              label='Muscle Aches'
                              checked={muscle}
                              onChange={() => setMuscle(!muscle)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'throat'}
                              type='checkbox'
                              label='Sore Throat'
                              checked={throat}
                              onChange={() => setThroat(!throat)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'headache'}
                              type='checkbox'
                              label='Headache'
                              checked={headache}
                              onChange={() => setHeadache(!headache)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'nose'}
                              type='checkbox'
                              label=' Congestion or runny nose'
                              checked={nose}
                              onChange={() => setNose(!nose)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'vomiting'}
                              type='checkbox'
                              label=' Nausea or vomiting'
                              checked={vomiting}
                              onChange={() => setVomiting(!vomiting)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                            <FormCheck
                              id={'exposure'}
                              type='checkbox'
                              label='Potential Exposure to COVID-19'
                              checked={exposure}
                              onChange={() => setExposure(!exposure)}
                              isInvalid={validated && !symptomsInvalid}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup className='mb-4'>
                            <FormLabel className='emph'>
                              Consent
                              <Required />
                            </FormLabel>
                            <FormCheck
                              id={'consent'}
                              type='checkbox'
                              label=' I agree'
                              onChange={() => setConsent(!consent)}
                              checked={consent}
                              // required
                              isInvalid={validated && !consent}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className='consent mb-4'>
                            <p>
                              1. In consideration for receiving COVID-19 testing
                              (which may include collection) and related
                              services (hereinafter collectively referred to as
                              “Testing”) provided in whole or in part by Covid
                              Solutions LLC (the “Company”), I hereby release,
                              waive, discharge, covenant not to sue, and hold
                              harmless for any and all purposes the Company and
                              their staff, members, shareholders, officers,
                              agents, volunteers, or employees (the Company and
                              the aforementioned persons are herein referred to
                              as “Indemnitees”) from any and all liabilities,
                              claims, demands, injuries (including death), or
                              damages, including court costs and attorney fees
                              and expenses, that may be sustained by me while
                              participating in Testing, while traveling to and
                              from the Testing, or while on the premises owned,
                              leased or occupied by Indemnitees.
                              <br />
                              <br />
                              2. I am fully aware and acknowledge that the
                              Testing provided by the Company may involve
                              COVID-19 tests that have not gone through a full
                              FDA approval process and instead obtained
                              emergency use authorization (EUA) or otherwise are
                              permitted for use under law. I am fully aware and
                              acknowledge that the results from Testing can
                              produce false positives or false negatives and I
                              am fully aware and acknowledge that the Testing
                              can be administered in a way that otherwise
                              produces inaccurate results. I am also fully aware
                              and acknowledge that the Company is not providing
                              medical care or giving a medical diagnosis and
                              that I should consult my doctor or health care
                              provider as to the results of Testing.
                              <br />
                              <br />
                              3. I hereby consent to the release of protected
                              health information (PHI) under HIPAA by the
                              Company to the extent necessary to complete the
                              Testing, to submit claims to health insurance
                              and/or state or federal health care programs for
                              reimbursement for Testing, and to allow the
                              Company to provide the results (whether positive
                              or negative) of the Testing to (1) the
                              organization which has arranged for the Testing,
                              (2) local and state public health authorities
                              (which may result in further direct communication
                              from those entities to me for further follow-up
                              and contract tracing), (3) the ordering physician
                              or other provider and/or ordering facility, and
                              (4) my health insurance or any state or federal
                              health care program providing reimbursement for
                              Testing. PHI will not be reused or disclosed by
                              the Company to any person or entity other than the
                              above, except as required by law.
                              <br />
                              <br />
                              4. I consent to the collection and testing of my
                              specimen by the Laboratory (“the Laboratory,” as
                              used herein, refers to the Company and/or any
                              reference laboratory used by the Company). I
                              certify that the specimen I have provided or will
                              provide for Testing by the Laboratory is my own,
                              and that the specimen is free from any
                              adulteration or contamination. I am voluntarily
                              submitting this specimen for analysis by the
                              Laboratory. I authorize the Laboratory to release
                              the results of the test to those individuals,
                              entities and authorities described in Paragraph 3.
                              <br />
                              <br />
                              5. I authorize the Laboratory to release to
                              applicable health insurance providers, CMS
                              (Centers for Medicare and Medicaid Services), or
                              any other applicable federal or state health care
                              program providing reimbursement for Testing, any
                              information needed to determine the benefits
                              payable.
                              <br />
                              <br />
                              6. [If I indicated that I have health insurance] I
                              acknowledge that the Laboratory may be out of
                              network with my health insurance. I authorize the
                              Laboratory to bill my health insurance and to
                              receive direct payment of benefits for Testing. I
                              understand that I am financially responsible for
                              all charges that are not covered by health
                              insurance. I am also aware that in some
                              circumstances my insurance provider may send the
                              payment directly to me for services provided.
                              Under law, I agree to endorse the insurance check
                              and forward it to the Company within 30 days of
                              receipt.
                              <br />
                              <br />
                              7. [If I indicated that I am uninsured] I
                              authorize the Laboratory to bill HRSA for Testing
                              and to receive direct payment of benefits for
                              Testing. By authorizing such billing, I hereby
                              certify that I do not have health insurance
                              through an individual or employer-sponsored plan,
                              a federal or state health care program (including
                              but not limited to Medicare, Medicaid (and any
                              state programs funded in whole or in part by
                              Medicaid), State Children’s Health Insurance
                              Program (SCHIP/CHIP), TriCare, VHA Program, Indian
                              Health Services (HIS), or U.S. Department of Labor
                              Workers’ Compensation), the Federal Employees
                              Health Benefits Program, or from any other source.
                              <br />
                              <br />
                              8. All assignments will remain in effect until
                              revoked by me in writing.
                              <br />
                              <br />
                              9. The Company does not represent or warrant that
                              my Testing will be covered in whole or in part by
                              any health insurer, payor, plan or program nor
                              does the Company represent or warrant that I will
                              not be responsible for the costs of my Testing,
                              regardless of the information I have provided on
                              this form.
                              <br />
                              <br />
                              10. I understand that email and other electronic
                              communications are not encrypted and may contain
                              my personal health information. I understand the
                              risks of unencrypted electronic communications and
                              I agree to the use of unencrypted electronic
                              communications from Covid Solutions LLC to the
                              email, phone number or other contact information I
                              have provided.
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <p>
                        By signing, I am agreeing to voluntary testing and
                        acknowledge and represent that I have read, understand,
                        and agree to all disclaimers, statements, and other
                        information contained on this form or otherwise provided
                        to me by Covid Solutions LLC, and that any information I
                        have provided is true and accurate.
                      </p>

                      <Row>
                        <Col md>
                          <FormLabel className='emph'>
                            Signature
                            <Required />
                          </FormLabel>
                          <div className='mb-4'>
                            <div
                              className='penCanvas'
                              style={{
                                borderColor:
                                  validated && !imageURL ? '#dc3545' : '',
                              }}
                            >
                              <SignatureCanvas
                                onEnd={save}
                                ref={sigCanvas}
                                canvasProps={{
                                  width: 500,
                                  height: 200,
                                  className: canvasValidation(),
                                }}
                              />
                            </div>
                            <img
                              src={refresh}
                              alt='refresh'
                              className='mb-3 m-2'
                              style={{ cursor: 'pointer' }}
                              onClick={clear}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <FormGroup controlId='dateOfBirth' className='mb-4'>
                            <FormLabel className='emph'>
                              Todays date
                              <Required />
                            </FormLabel>
                            <FormControl
                              type='date'
                              onChange={(e) => HandleChange(e, setTodaysDate)}
                              value={todaysDate}
                              required
                              isInvalid={validated && !todaysDate}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button type='submit' className='mb-4' size='lg'>
                        Submit
                      </Button>
                    </Form>
                    {message && (
                      <div className='fixedAlert'>
                        <Alert
                          variant='success'
                          onClose={() => setMessage('')}
                          dismissible
                        >
                          {message}
                        </Alert>
                      </div>
                    )}
                    {error && (
                      <div className='fixedAlert'>
                        <Alert
                          variant='danger'
                          onClose={() => setError('')}
                          dismissible
                        >
                          {error}
                        </Alert>
                      </div>
                    )}
                    {errorMessage && (
                      <div className='fixedAlert'>
                        <Alert
                          variant='danger'
                          onClose={() => setErrorMessage('')}
                          dismissible
                        >
                          {errorMessage?.message}
                          {errorMessage?.code && (
                            <div>
                              <br />
                              ErrorCode: {errorMessage?.code}
                            </div>
                          )}
                        </Alert>
                      </div>
                    )}
                  </Container>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormSubmission;
