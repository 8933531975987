import React, { useEffect } from 'react';
import { Layout } from './Home';

const ComingSoon = () => {

  useEffect(() => {
    const config = {
      selector: '.storerocket-store-locator',
      account: 'n0pjv02p6V'
    };

    window.StoreRocket.init(config);
  }, []);

  return (
    <>
      <Layout>
        <div className='main'>
          <div className='locations'>
            <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Coming soon</h1>
            <p>New testing locations opening soon near you</p>
            <div
              style={{ width: '100%' }}
              className='storerocket-store-locator'
            ></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ComingSoon;
