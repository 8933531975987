import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../assets/logoCst.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Verify = () => {
  return (
    <div className='App'>
      <Container style={{ maxWidth: 1200 }}>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='companyName'> Covid Solutions LLC </div>
        <div className='addressInfo'>
          <div className='cliaNumber'> CLIA ID Number: 14D2246256 </div>
          <div className='companyAddress'>
            191 North Ave Suite 110 <br />
            West Chicago, IL 60185 <br />
            Phone: +1 (630) 526-0287 <br />
          </div>
        </div>
        <div className='verifyText'>
          <p>
            The patient you are trying to verify has been tested at Covid
            Solutions LLC for coronavirus COVID-19 Antigen.
          </p>
          <p>
            Due to HIPPA laws, we cannot display the patient’s information. If
            you need the copy of the report, please call our lab at{' '}
                      <strong>+1 (630) 526-0287</strong>.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Verify;
