import React, { useEffect } from 'react';
import { Layout } from './Home';
import ReactGA from 'react-ga';

const TestingLocations = () => {
  useEffect(() => {
    const config = {
      selector: '.storerocket-store-locator',
      account: 'DG4gZDbp05',
    };

    window.StoreRocket.init(config);
  }, []);

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Layout>
        <div className='main'>
          <div className='locations'>
            <h1 style={{ textAlign: 'center', marginTop: '50px' }}>
              Testing locations
            </h1>
            <p>Find the nearest testing location</p>
            <div
              style={{ width: '100%' }}
              className='storerocket-store-locator'
            ></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TestingLocations;
