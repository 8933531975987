import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import logo from '../assets/logoCst.svg';
import menu from '../assets/menu.svg';
import phone from '../assets/phone-call.svg';
import x from '../assets/x.svg';
import { Spinner } from './Form';
import ReactGA from 'react-ga';

const API_URL = process.env.REACT_APP_API_URL;

const Navigation = ({ sidenav, setSidenav }) => {
  return (
    <div className='navigation'>
      <div className='navigationInner'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt='logo' className='navlogo' />
          <div className='navLogoText'>Covid Solutions</div>
        </div>
        <div className='navigationLinks'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLinkActive' : 'navLink'
            }
            to='/'
          >
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLinkActive' : 'navLink'
            }
            to='/testing-locations'
          >
            Testing Locations
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLinkActive' : 'navLink'
            }
            to='/faq'
          >
            FAQ
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLinkActive' : 'navLink'
            }
            to='/about-us'
          >
            About us
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLinkActive' : 'navLink'
            }
            to='/contact'
          >
            Contact
          </NavLink>
              <a href='tel:6305260287' style={{ marginLeft: 20 }}>
            <img src={phone} alt='phone' style={{ width: 25 }} />
          </a>
        </div>
        <img
          src={menu}
          alt='menu'
          className='menu'
          onClick={() => setSidenav(true)}
          style={{ width: 30 }}
        />
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <>
      <div className='footer'>
        <h1>Covid Solutions</h1>
        <div className='footerNav'>
          <Link to='/'>Home</Link>
          <Link to='/testing-locations'>Testing Locations</Link>
          <Link to='/about-us'>About us</Link>

          <Link to='/faq'>FAQ</Link>
          <Link to='/contact'>Contact</Link>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>
        <div className='phone'>
           <a href='tel:6305260287'> +1 (630) 526-0287 </a>
        </div>
      </div>

      <div className='copyright'>©2021 by covidsolutionstoday.org</div>
    </>
  );
};

const SideNav = ({ setSidenav }) => {
  return (
    <div className='sidenavmenu'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <img src={logo} alt='logo' className='navlogo' />
        <img
          src={x}
          alt='logo'
          className='navlogo'
          onClick={() => setSidenav(false)}
          style={{ width: 30 }}
        />
      </div>
      <NavLink
        className={({ isActive }) => (isActive ? 'navLinkActive' : 'navLink')}
        to='/'
        style={{ margin: '20px 0', display: 'block' }}
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'navLinkActive' : 'navLink')}
        to='/contact'
        style={{ margin: '20px 0', display: 'block' }}
      >
        Contact
      </NavLink>
    </div>
  );
};

export const Layout = ({ children }) => {
  const [sidenav, setSidenav] = useState(false);

  return (
    <Sidebar
      sidebar={<SideNav setSidenav={setSidenav} />}
      open={sidenav}
      onSetOpen={() => setSidenav(true)}
      styles={{ sidebar: { background: 'white' } }}
    >
      <Navigation setSidenav={setSidenav} sidenav={sidenav} />
      {children}
      <Footer />
    </Sidebar>
  );
};

const Home = () => {
  const [locations, setLocations] = useState([]);
  const [locationsLoad, setLocationsLoad] = useState(false);

  const fetchLocations = useCallback(async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    setLocationsLoad(true);
    const data = await axios.get(
      `${API_URL}/Locations?visibleOnly=true`,
      config
    );
    setLocations(data.data);
    setLocationsLoad(false);
  }, []);

  useEffect(() => {
    fetchLocations().catch(console.error);
  }, []);

  useEffect(() => {
    const config = {
      selector: '.storerocket-store-locator',
      account: 'DG4gZDbp05',
    };

    window.StoreRocket.init(config);
  }, []);

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {locationsLoad && <Spinner />}
      <Layout>
        <div className='hero'>
          <div style={{ margin: '0 20px' }}>
            <p>CLIA Certified Lab</p>
            <h1>Free Covid-19 Testing</h1>
            <p>With Most Insurances</p>
            <p>No Appointment Needed</p>
          </div>
        </div>
        <div className='main'>
          <h1 style={{ textAlign: 'center', marginTop: '50px' }}>
            Our services
          </h1>
          <div className='ourServicesHome'>
            <div>
              <h1>Rapid Test</h1>
              <ul>
                <li>Nasal swab</li>
                <li>Results within 30 minutes</li>
                <li>Walk-ins welcome</li>
              </ul>
            </div>
            <div>
              <h1>PCR Test</h1>
              <ul>
                <li>Saliva test</li>
                <li>Results in 24-48 hours</li>
                <li>Walk-ins welcome</li>
              </ul>
            </div>
          </div>
          <div className='locations'>
            <h1>Testing Locations</h1>
            <div
              style={{ width: '100%' }}
              className='storerocket-store-locator'
            ></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
