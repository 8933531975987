import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import { Required, Spinner } from './Form';
import { Layout } from './Home';
import ReactGA from 'react-ga';

const API_URL = process.env.REACT_APP_API_URL;

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const HandleChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const configPost = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const data = await axios.post(
        `${API_URL}/Contact`,
        {
          name,
          email,
          subject,
          message,
        },
        configPost
      );
      setSuccess(data === true ? 'Success!' : '');
      setTimeout(() => {
        setSuccess('');
      }, 5000);
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    } finally {
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Layout>
        <div className='contactUs'>
          <div className='contactImage'></div>
          <div className='contactForm'>
            <Row style={{ maxWidth: 400, width: '100%' }}>
              <Col md={12}>
                <h1>Contact Us</h1>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <FormGroup controlId='name' className='mb-4'>
                    <FormLabel className='name'>
                      Name
                      <Required />
                    </FormLabel>
                    <FormControl
                      type='text'
                      placeholder='Enter your name'
                      required
                      onChange={(e) => HandleChange(e, setName)}
                      value={name}
                      maxLength={50}
                      // isInvalid={validated && !firstName}
                      // isInvalid={!firstName}
                    />
                  </FormGroup>
                  <FormGroup controlId='email' className='mb-4'>
                    <FormLabel className='email'>
                      Email
                      <Required />
                    </FormLabel>
                    <FormControl
                      type='email'
                      placeholder='Enter your email'
                      onChange={(e) => HandleChange(e, setEmail)}
                      required
                      value={email}
                      maxLength={50}
                    />
                  </FormGroup>
                  <FormGroup controlId='subject' className='mb-4'>
                    <FormLabel className='subject'>
                      Subject
                      {/* <Required /> */}
                    </FormLabel>
                    <FormControl
                      type='text'
                      placeholder='Type the subject'
                      onChange={(e) => HandleChange(e, setSubject)}
                      value={subject}
                      maxLength={50}
                    />
                  </FormGroup>
                  <FormGroup controlId='message' className='mb-4'>
                    <FormLabel className='message'>
                      Message
                      {/* <Required /> */}
                    </FormLabel>
                    <FormControl
                      type='textarea'
                      placeholder='Type your message here...'
                      onChange={(e) => HandleChange(e, setMessage)}
                      value={message}
                      as='textarea'
                      rows={3}
                      maxLength={250}
                    />
                  </FormGroup>
                  <Button
                    variant='primary'
                    type='submit'
                    className='mb-4 submitButton'
                    size='lg'
                  >
                    Submit
                  </Button>
                  {success && <Alert variant='success'>{success}</Alert>}
                  {error && <Alert variant='danger'>{error}</Alert>}
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
