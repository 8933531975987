import React, { useEffect } from 'react';
import { Layout } from './Home';
import ReactGA from 'react-ga';

const AboutUs = () => {
  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS;
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {/* {locationsLoad && <Spinner />} */}
      <Layout>
        <div className='hero heroAbout'>
          <div style={{ margin: '0 20px' }}>
            <h1>About Us</h1>
            <p>Quality Care You Can Trust</p>
          </div>
        </div>
        <div className='main mainAbout'>
          <div className='ourServices'>
            <h1>Who We Are</h1>
            <p>
              We are a licensed, CLIA certified, laboratory that is dedicated to
              informing the public of their current COVID-19 result status. Our
              lab is based out of West Chicago, IL- and have convenient
              locations in the surrounding areas. We are committed to providing
              you with exceptional care and professionalism. We are taking
              precautionary measures to ensure your health and safety as we go
              through this together.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;
